import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  sales: [
    {
      id: 1,
      date: '2020/11/18',
      code: '20201021-022129',
      name: 'Martil',
      toko: 'TB.0001',
      unit: 'EACH',
      price: '33600',
      status: 'Avaible',
    },
    {
      id: 2,
      date: '2020/12/08',
      code: '20201021-022129',
      name: 'Paku',
      toko: 'TB.0001',
      unit: 'PACK',
      price: '33600',
      status: 'NotAvaible',
    },
    {
      id: 3,
      date: '2020/10/29',
      code: '20201021-022129',
      name: 'Gergaji',
      toko: 'TB.0001',
      unit: 'EACH',
      price: '33600',
      status: 'Avaible',
    },
    {
      id: 4,
      date: '2020/03/03',
      code: '20201021-022129',
      name: 'Sekop',
      toko: 'TB.0001',
      unit: 'EACH',
      price: '33600',
      status: 'Avaible',
    },
    {
      id: 5,
      date: '2020/07/18',
      code: '20201021-022129',
      name: 'Galvalum',
      toko: 'TB.0001',
      unit: 'EACH',
      price: '33600',
      status: 'NotAvaible',
    },
    {
      id: 6,
      date: '2020/11/6',
      code: '20201021-022129',
      name: 'Pipa PVC',
      toko: 'TB.0001',
      unit: 'PCS',
      price: '33600',
      status: 'NotAvaible',
    },
    {
      id: 7,
      date: '2020/11/18',
      code: '20201021-022129',
      name: 'Kuas',
      toko: 'TB.0001',
      unit: 'PCS',
      price: '33600',
      status: 'NotAvaible',
    },
    {
      id: 8,
      date: '2020/01/11',
      code: '20201021-022129',
      name: 'Semen Putih 50Kg',
      toko: 'TB.0001',
      unit: 'ZAK',
      price: '33600',
      status: 'Avaible',
    },
    {
      id: 9,
      date: '2020/12/19',
      code: '20201021-022129',
      name: 'Semen Gresik 50Kg',
      toko: 'TB.0001',
      unit: 'ZAK',
      price: '33600',
      status: 'Avaible',
    },
    {
      id: 10,
      date: '2020/01/8',
      code: '20201021-022129',
      name: 'Semen Gresik 40Kg',
      toko: 'TB.0001',
      unit: 'ZAK',
      price: '33600',
      status: 'Avaible',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/pricelist').reply(() => [200, data.sales])
