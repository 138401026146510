import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  sales: [
    {
      id: 1,
      name: 'TB.0001',
      code: '728192',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '100',
      products: '34',
      customers: '330',
      createBy: 'Administrator',
      status: 'Terminated',
    },
    {
      id: 2,
      name: 'TB.0002',
      code: '872918',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '0',
      products: '21',
      customers: '102',
      createBy: 'Administrator',
      status: 'Terminated',
    },
    {
      id: 3,
      name: 'TB.0003',
      code: '782912',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '0',
      products: '7',
      customers: '87',
      createBy: 'Administrator',
      status: 'Active',
    },
    {
      id: 4,
      name: 'TB.0004',
      code: '666732',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '34',
      products: '63',
      customers: '278',
      createBy: 'Administrator',
      status: 'Active',
    },
    {
      id: 5,
      name: 'TB.0005',
      code: '777362',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '100',
      products: '49',
      customers: '500',
      createBy: 'Administrator',
      status: 'Active',
    },
    {
      id: 6,
      name: 'TB.0006',
      code: '772839',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '0',
      products: '0',
      customers: '0',
      createBy: 'Administrator',
      status: 'Terminated',
    },
    {
      id: 7,
      name: 'TB.0007',
      code: '645821',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '239',
      products: '65',
      customers: '302',
      createBy: 'Administrator',
      status: 'Active',
    },
    {
      id: 8,
      name: 'TB.0008',
      code: '213721',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '100',
      products: '34',
      customers: '330',
      createBy: 'Administrator',
      status: 'Terminated',
    },
    {
      id: 9,
      name: 'TB.0009',
      code: '737238',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '82',
      products: '12',
      customers: '21',
      createBy: 'Administrator',
      status: 'Active',
    },
    {
      id: 10,
      name: 'TB.0010',
      code: '213912',
      address: 'Surabaya',
      phone: '0882918292',
      qty: '100',
      products: '34',
      customers: '330',
      createBy: 'Administrator',
      status: 'Active',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/tokoBangunan').reply(() => [200, data.sales])
